import React, { useState, useEffect } from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  
  // Carousel state
  const [currentSlide, setCurrentSlide] = useState(0);
  
  // Carousel images
  const carouselImages = [
    '/images/carousel/slide-1.jpeg',
    '/images/carousel/slide-2.jpeg',
    '/images/carousel/slide-3.jpeg',
    '/images/carousel/slide-4.jpeg',
    '/images/carousel/slide-5.jpeg',
  ];
  
  // Auto-rotate carousel
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => 
        prevSlide === carouselImages.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000); // Change slide every 5 seconds
    
    return () => clearInterval(interval);
  }, [carouselImages.length]);

  const products = [
    {
      id: 'powertools',
      title: 'Powertools & Machines',
      mainImage: '/images/home/powertools/powertools-machines-home.webp',
      hoverImages: [
        '/images/home/powertools/powertools-1.png',
        '/images/home/powertools/powertools-2.png',
        '/images/home/powertools/powertools-3.png'
      ],
      route: '/products/p-and-m/powertools'
    },
    {
      id: 'machine',
      title: 'Automotive',
      mainImage: '/images/home/spares/automotive.webp',
      hoverImages: [
        '/images/home/spares/commercial-home.webp',
        '/images/home/spares/passenger-home.webp',
      ],
      route: '/products/p-and-m/machines'
    },
    {
      id: 'diesel-spares',
      title: 'Diesel Spares',
      mainImage: '/images/home/dc/s/dc-spares.jpeg',
      hoverImages: [
        '/images/home/spares/home-spares-1.png',
        '/images/home/spares/home-spares-2.png',
        '/images/home/spares/home-spares-3.png'
      ],
      route: '/products/diesel-center'
    },
    {
      id: 'diesel-services',
      title: 'Diesel Services',
      mainImage: '/images/home/dc/dc-2.webp',
      hoverImages: [
        '/images/home/dc/dc-1.webp',
        '/images/home/dc/main.jpeg',
        '/images/home/dc/dc-3.webp'
      ],
      route: '/diesel-center'
    }
    // ... add other products similarly
  ];
  
  // Partner logos
  const partners = [
    { name: 'Bosch', logo: '/images/partners/bosch.png' },
    { name: 'Denso', logo: '/images/partners/denso.png' },
    { name: 'Pertec', logo: '/images/partners/pertec.png' },
    { name: 'Frasle', logo: '/images/partners/frasle.png' },
    { name: 'Aeropro', logo: '/images/partners/aeropro.png' },
    { name: 'Livguard', logo: '/images/partners/livguard.png' },
  ];

  function nagivateTo(route) {
    window.scrollTo(0, 0);
    navigate(route);
  }

  return (
    <div className='home-wrapper'>
      {/* Image Carousel */}
      <div className="carousel-container">
        <div className="carousel-slides" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          {carouselImages.map((image, index) => (
            <div key={index} className="carousel-slide">
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </div>
        <div className="carousel-indicators">
          {carouselImages.map((_, index) => (
            <button 
              key={index} 
              className={`carousel-indicator ${currentSlide === index ? 'active' : ''}`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
      </div>

      {/* Welcome Section */}
      <div className="home-container welcome-section">
        <div className='company-info'>
          <h1>Welcome to <span className="highlight">Parts Xpress</span></h1>
          <div className="welcome-content">
            <div className="welcome-image">
              <img src="/images/home/company-showroom.jpg" alt="Parts Xpress Showroom" />
            </div>
            <div className="welcome-text">
              <p className="company-description">
                Part Xpress Limited is a leading provider of top-quality automotive parts, diesel services, power tools with accessories, and a wide range of light machinery. We are committed to delivering exceptional products and services to our customers, ensuring their needs are met with the highest standards of quality, reliability, and professionalism.
              </p>
              <div className="welcome-features">
                <div className="welcome-feature">
                  <i className="fas fa-check-circle"></i>
                  <span>Quality Products</span>
                </div>
                <div className="welcome-feature">
                  <i className="fas fa-check-circle"></i>
                  <span>Expert Service</span>
                </div>
                <div className="welcome-feature">
                  <i className="fas fa-check-circle"></i>
                  <span>Global Partnerships</span>
                </div>
                <div className="welcome-feature">
                  <i className="fas fa-check-circle"></i>
                  <span>Customer Satisfaction</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-container">
        <div className='company-info'>
          <h1>Our Products</h1>

          <div className="services-container">
            {products.map((service) => (
              <div key={service.id} className="service-card" onClick={() => nagivateTo(service.route)}>
                <div className="main-image-container">
                  <img
                    src={service.mainImage}
                    alt={service.title}
                    className="main-image"
                  />
                  <div className="overlay">
                    <div className="content">
                      <h2>{service.title}</h2>
                      <p>{service.description}</p>
                    </div>
                  </div>
                </div>

                <div className="hover-images">
                  {service.hoverImages.map((img, index) => (
                    <div key={index} className="hover-image-container">
                      <img
                        src={img}
                        alt={`${service.title} preview ${index + 1}`}
                        className="hover-image"
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


      <div className="home-container why-choose-us">
        <div className='company-info'>
          <h1>Why Choose Us?</h1>
          <p className="section-description">We provide superior automotive and industrial solutions with exceptional service and expertise.</p>
          <div className='choose-us-box'>
            <div className='choose-us-card'>
              <div className="choose-us-icon-container">
                <img src="/images/icons/inventory.png" alt="Inventory Icon" className="choose-us-icon" />
              </div>
              <h3>Extensive Inventory</h3>
              <p>
                Choose from thousands of high-quality parts for both passenger and commercial vehicles.
              </p>
              {/* <div className="choose-us-card-footer">
                <span>View Products</span>
                <i className="fas fa-arrow-right"></i>
              </div> */}
            </div>
            <div className='choose-us-card'>
              <div className="choose-us-icon-container">
                <img src="/images/icons/trusted-quality.png" alt="Quality Icon" className="choose-us-icon" />
              </div>
              <h3>Trusted Quality</h3>
              <p>
                Our parts are sourced from leading global manufacturers, ensuring reliability and performance.
              </p>
              {/* <div className="choose-us-card-footer">
                <span>Our Partners</span>
                <i className="fas fa-arrow-right"></i>
              </div> */}
            </div>
            <div className='choose-us-card'>
              <div className="choose-us-icon-container">
                <img src="/images/icons/customer-support.png" alt="Support Icon" className="choose-us-icon" />
              </div>
              <h3>Expert Support</h3>
              <p>
                Get professional assistance from our knowledgeable team to find the right solutions for your needs.
              </p>
              {/* <div className="choose-us-card-footer">
                <span>Contact Us</span>
                <i className="fas fa-arrow-right"></i>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      
      {/* Partners Section */}
      <div className="home-container partners-section">
        <div className='company-info'>
          <h1>Our Partners</h1>
          <div className="partners-logo-container">
            {partners.map((partner, index) => (
              <div key={index} className="partner-logo">
                <img src={partner.logo} alt={`${partner.name} logo`} />
                {/* <p>{partner.name}</p> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
