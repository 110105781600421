import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header'; // Import the Header component
import './App.css';
import Footer from './components/Footer';
import DieselCenter from './pages/DieselCenter/DieselCenter';
import Products from './pages/Products';
import PowertoolsDetails from './components/PowertoolsDetails';
import MachinesDetails from './components/MachinesDetails';
import AccessoriesDetails from './components/AccessoriesDetails';
import CardDetails from './components/CardDetails';
import DieselCenterDetails from './components/DieselCenterDetails';

function App() {
  return (
    <Router>
      <div className="AppContainer">
        <Header /> {/* Add Header component here */}
        <main className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/diesel-center" element={<DieselCenter />} />
            <Route path="/diesel-center/:category" element={<DieselCenter />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:category" element={<Products />} />
            <Route path="/products/:category/:subcategory" element={<Products />} />
            <Route path='powertools/:id' element={<PowertoolsDetails />} />
            <Route path='machine/:id' element={<MachinesDetails />} />
            <Route path='accessory/:id' element={<AccessoriesDetails />} />
            <Route path='/parts-detail/:type/:id' element={<CardDetails />} />
            <Route path='/diesel-center/spares/:id' element={<DieselCenterDetails />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
