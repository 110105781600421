// src/components/ProductCard.js
import React from 'react';
import './MachinesCard.css'; // Assuming you want to use the same styles
import { Link } from 'react-router-dom';

const MachinesCard = ({ item }) => {
  return (
    <div className="mc-card">
      <div className="mc-card-content">
        <div className="mc-card-header">
          <h3 className="mc-part-number">{item.description}</h3>
        </div>
        
        <div className="mc-card-details">
          <div className="mc-detail-row">
            <span className="mc-label">Model:</span>
            <span className="mc-value">{item.id || 'N/A'}</span>
          </div>

          {/* <div className="mc-detail-row">
            <span className="mc-label">Details:</span>
            <span className="mc-value">{item.details || 'N/A'}</span>
          </div> */}
          
          {/* <div className="mc-detail-row">
            <span className="mc-label">EAN1:</span>
            <span className="mc-value">{item.ean1 || 'N/A'}</span>
          </div>

          <div className="mc-detail-row">
            <span className="mc-label">EAN2:</span>
            <span className="mc-value">{item.ean2 || 'N/A'}</span>
          </div> */}
        </div>
      </div>

      <div className="mc-button-container">
        <Link to={`/machine/${encodeURIComponent(item.uuid)}`} className="mc-button">
          View Details
        </Link>
        {/* {item.url && (
          <Link to={item.url} className="mc-button" target="_blank" rel="noopener noreferrer">
            View Product
          </Link>
        )} */}
      </div>

      {/* {item.url && (
        <Link to={item.url} className="mc-read-more" target="_blank" rel="noopener noreferrer">
          View Product
        </Link>
      )} */}
    </div>
  );
};

export default MachinesCard;