import commercialData from '../data/commercial.json'
import accessoriesData from '../data/accessories.json'
import powertoolsData from '../data/power-tools.json'
import passengerData from '../data/passenger.json'
import machinesData from '../data/machines.json'
import dieselCenterData from '../data/diesel-center-products.json'

export class DataService {
  static getPowertoolsAccessoriesData() {
    const accessoriesDataValues = this.getAllNestedValues(accessoriesData)
    const data = [
      {
        id: 'all',
        name: 'All',
        items: [...powertoolsData, ...machinesData, ...accessoriesDataValues]
      },
      {
        id: 'powertools',
        name: 'Power Tools',
        items: powertoolsData
      },
      {
        id: 'machines',
        name: 'Machines',
        items: machinesData
      },
      {
        id: 'accessories',
        name: 'Accessories',
        items: accessoriesDataValues
      }, 
    ]

    return data
  }

  static getAutoSparePartsData() {
    const commercialDataValues = this.getAllNestedValues(commercialData)
    const passengerDataValues = this.getAllNestedValues(passengerData)

    const data = [
      {
        id: 'all',
        name: 'All',
        items: [...commercialDataValues, ...passengerDataValues]
      },
      {
        id: 'commercial',
        name: 'Commercial Vehicles',
        items: commercialDataValues
      },
      {
        id: 'passenger',
        name: 'Passenger Vehicles',
        items: passengerDataValues
      }
    ]

    return data
  }

  static getDieselCenterData() {
    const dieselCenterValues = this.getAllNestedValues(dieselCenterData)

    // Group items by category
    const plungerItems = dieselCenterValues.filter(item => item.Category === 'PLUNGER')
    const spareItems = dieselCenterValues.filter(item => item.Category === 'SPARE')
    const nozzleItems = dieselCenterValues.filter(item => item.Category === 'NOZZLE')

    const data = [
      {
        id: 'all',
        name: 'All',
        items: dieselCenterValues
      },
      {
        id: 'plunger',
        name: 'Plunger',
        items: plungerItems
      },
      {
        id: 'spare',
        name: 'Spare',
        items: spareItems
      },
      {
        id: 'nozzle',
        name: 'Nozzle',
        items: nozzleItems
      }
    ]

    return data
  }

  static getAllNestedValues = (obj) => {
    const flattenedValues = [];

    // Helper function to capitalize words
    const formatPartName = (key) => {
      return key.split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    // Iterate through entries to get both key and value
    Object.entries(obj).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        // Add PART field to each object in the array
        const itemsWithPart = value.map(item => ({
          ...item,
          PART: formatPartName(key)
        }));
        flattenedValues.push(...itemsWithPart);
      } else {
        flattenedValues.push({
          ...value,
          PART: formatPartName(key)
        });
      }
    });
    return flattenedValues;
  }
}