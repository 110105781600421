import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navRef = useRef(null);

  // Toggle mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Toggle individual dropdown
  const toggleDropdown = (dropdownName) => {
    // console.warn('toggleDropdown', dropdownName, activeDropdown);
    if (window.innerWidth <= 768) {
      setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
    } else {
      setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
    }
  };

  // Close menu and dropdown when clicking a link
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
    setActiveDropdown(null);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsMenuOpen(false);
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Reset states on window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
      setActiveDropdown(null);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigateToHome = () => {
    navigate('/');
    handleLinkClick()
  };

  return (
    <>
      {/* <div className="gradient" /> */}
      <header className="header" ref={navRef}>
        <div className="hamburger" onClick={toggleMenu}>
          {isMenuOpen ? '✖' : '☰'} {/* 'X' for close, '☰' for open */}
        </div>
        
        <div className="header-center">
          {/* Logo Section */}
          <div className="header-logo-container">
            <img className="logo" onClick={navigateToHome} src="/images/cropped-logo.png" alt="Company Logo" />
            <div className="company-name" onClick={navigateToHome}>
              <p>Parts Xpress</p>
              {/* <p>Limited</p> */}
            </div>
          </div>

          {/* Navigation Links */}
          <nav className={`header-nav-links ${isMenuOpen ? 'open' : ''}`}>
            <div className="header-dropdown">
              <Link to="/diesel-center" onClick={() => {
                toggleDropdown('diesel')
                handleLinkClick()
              }}>Diesel Center</Link>
              <div className={`header-dropdown-content ${activeDropdown === 'diesel' || isMenuOpen ? 'show' : ''}`}>
                <Link to="/products/diesel-center" onClick={handleLinkClick}>Spares</Link> {/* Dropdown item */}
                <Link to="/diesel-center" onClick={handleLinkClick}>Services</Link> {/* Dropdown item */}
              </div>
            </div>
            <div className="header-dropdown">
              <Link to="/products/p-and-m" onClick={() => {
                toggleDropdown('powertools')
                handleLinkClick()
              }}>Powertools & Machines</Link>
              <div className={`header-dropdown-content ${activeDropdown === 'powertools' || isMenuOpen ? 'show' : ''}`}>
                <Link to="/products/p-and-m/powertools" onClick={handleLinkClick}>Tools</Link> {/* Dropdown item */}
                <Link to="/products/p-and-m/machines" onClick={handleLinkClick}>Machines</Link> {/* Dropdown item */}
                <Link to="/products/p-and-m/accessories" onClick={handleLinkClick}>Accessories</Link> {/* Dropdown item */}
              </div>
            </div>
            <div className="header-dropdown">
              <Link to="/products/spare-parts" onClick={() => {
                toggleDropdown('parts')
                handleLinkClick()
              }}>Auto spare parts</Link>
              <div className={`header-dropdown-content ${activeDropdown === 'parts' || isMenuOpen ? 'show' : ''}`}>
                <Link to="/products/spare-parts/commercial" onClick={handleLinkClick}>Commercial</Link> {/* Dropdown item */}
                <Link to="/products/spare-parts/passenger" onClick={handleLinkClick}>Passenger</Link> {/* Dropdown item */}
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
