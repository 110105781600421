import React from 'react';
import { Link } from 'react-router-dom';
import './DieselCenterCard.css';

const formatName = (key) => {
  return key.toLowerCase().split(' ')
    .map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word)
    .join(' ');
};

const DieselCenterCard = ({ item }) => {
  // console.log(item);
  return (
    <div className="dcc-card">
      <div className="dcc-card-content">
        <div className="dcc-card-header">
          <h3 className="dcc-part-number">{item['PART']}</h3>
          <p className="dcc-bosch-number">Part Number: {item['Part Number']}</p>
        </div>

        <div className="dcc-card-details">
          <div className="dcc-detail-row">
            <span className="dcc-label">Part Name:</span>
            <span className="dcc-value">{formatName(item['Part Name'] || 'N/A')}</span>
          </div>

          <div className="dcc-detail-row">
            <span className="dcc-label">Category:</span>
            <span className="dcc-value">{formatName(item['Category'] || 'N/A')}</span>
          </div>
        </div>

        {/* <div className="dcc-card-details"> */}
          
        {/* </div> */}
      </div>

      <div className="dcc-button-container">
        <Link to={`/diesel-center/spares/${encodeURIComponent(item['Part Number'])}`} className="dcc-button">
          View Details
        </Link>
        {item.url && (
          <Link to={item.url} className="dcc-button" target="_blank" rel="noopener noreferrer">
            View Product
          </Link>
        )}
      </div>
    </div>
  );
};

export default DieselCenterCard; 