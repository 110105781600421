import React from 'react';
import './AccessoriesCard.css'; // Assuming you want to use the same styles
import { Link } from 'react-router-dom';

const AccessoriesCard = ({ item }) => {
  return (
    <div className="acc-card">
      <div className="acc-card-content">
        <div className="acc-card-header">
          <h3 className="acc-part-number">{item.Description}</h3>
        </div>

        <div className="acc-card-details">
          <div className="acc-detail-row">
            <span className="acc-label">Model:</span>
            <span className="acc-value">{item.Part_Nr || 'N/A'}</span>
          </div>
        </div>
      </div>

      <div className="acc-button-container">
        <Link to={`/accessory/${encodeURIComponent(item.Part_Nr)}`} className="acc-button">
          View Details
        </Link>
      </div>
    </div>
  );
};

export default AccessoriesCard;