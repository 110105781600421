// src/components/Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-column">
          <h3>Contact Us</h3>
          <div className='footer-details'>
            <p><strong>Address:</strong></p>
            <p>Plot No. 21, Vingunguti Industrial Area, <br/>Nyerere Rd, Dar es Salaam, Tanzania</p>
            <p><strong>Phone:</strong></p>
            <p>+255 781 900 001 / 002</p>
            <p><strong>Email:</strong></p>
            <p>sales@partsxpress.co.tz</p>
          </div>
        </div>
        
        <div className="footer-column">
          <h3>Business Hours</h3>
          <div className='footer-details'>
            <p><strong>Monday - Friday:</strong> 8:00 AM - 5:00 PM</p>
            <p><strong>Saturday:</strong> 8:00 AM - 2:00 PM</p>
            <p><strong>Sunday:</strong> Closed</p>
          </div>
        </div>
        
        <div className="footer-column">
          <h3>Quick Links</h3>
          <ul className="footer-links">
            <li><a href="/">Home</a></li>
            <li><a href="/diesel-center">Diesel Center</a></li>
            <li><a href="/products/p-and-m">PowerTool & Machines</a></li>
            <li><a href="/products/spare-parts">Spare Parts</a></li>
            {/* <li><a href="/about">About Us</a></li>
            <li><a href="/contact">Contact</a></li> */}
          </ul>
        </div>
      </div>
      
      <div className="footer-social">
        <h3>Connect With Us</h3>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p className='rights-reserved'>© 2024 Parts Xpress. All rights reserved.</p>
        {/* <p className="footer-legal">
          <a href="/privacy-policy">Privacy Policy</a> | 
          <a href="/terms-of-service">Terms of Service</a>
        </p> */}
      </div>
    </footer>
  );
}

export default Footer;