import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './DieselCenterDetails.css';
import { DataService } from '../services/DataService';
import Loading from './Loading';
import ProductNotFound from './ProductNotFound';

const DieselCenterDetails = () => {
  const { id } = useParams();
  const [part, setPart] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const dieselData = DataService.getDieselCenterData();
    const foundPart = dieselData
      .find(category => category.id === 'all')?.items
      .find(item => item['Part Number'] === id);

    window.scrollTo(0, 0);

    setTimeout(() => {
      setIsLoading(false);
      if (foundPart) {
        setPart(foundPart);
      }
    }, 1000);
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }

  if (!part) {
    return <ProductNotFound />;
  }

  const bannerImage = () => {
    // console.log('tool.PART', part.PART);
    if (part.PART === 'Delivery Valve & Holder') {
      return '/images/details/diesel-center-delivery-valve.png'
    } else if (part.PART === 'Pump Element') {
      return '/images/details/diesel-center-pump-element-banner.png'
    } else if (part.PART === 'Nozzle Tips') {
      return '/images/details/diesel-center-nozzle-tips-banner.png'
    } else if (part.PART === 'Came Plate & Came Ring') {
      return '/images/details/diesel-center-came-plate-banner.png'
    } else if (part.PART === 'Timing Device Piston & Drive Shaft & Shaft Seal') {
      return '/images/details/diesel-center-timing-device-banner.png'
    } else if (part.PART === 'Pump Housing & Market & Flyweight') {
      return '/images/details/diesel-center-pump-housing-banner.png'
    } else if (part.PART === "O Ring's & Unit Repair Kit") {
      return '/images/details/diesel-center-o-ring-banner.png'
    } else if (part.PART === "Hydraulic Head Rotor") {
      return '/images/details/diesel-center-hydraulic-head-banner.png'
    } else if (part.PART === "Pressure Spindle & Adaptor Plate") {
      return '/images/details/diesel-center-pressure-banner.png'
    } else if (part.PART === "Overflow Valve & Hollow Bolt") {
      return '/images/details/diesel-center-overflow-valve-banner.png'
    }
    else if (part.PART === "Flat Seal Ring & Compression Sprint & Seal Ring") {
      return '/images/details/diesel-center-flat-seal-banner.png'
    } else {
      return '/images/details/diesel-center-delivery-valve.png'
    }

  }

  return (
    <div className="dcd-powertools-details">
      <div className="dcd-powertools-banner">
        <img
          src={bannerImage()}
          alt="Diesel Center Category Banner"
          className="dcd-powertools-banner-image"
        />
        <div className="dcd-powertools-banner-overlay">
          <h2 className="dcd-powertools-details-title">{part.PART}</h2>
        </div>
      </div>

      <div className="dcd-powertools-details-container">
        <div className="dcd-powertools-details-card">
          <div className="dcd-details-section">
            <h3 className="dcd-section-title">Product Details</h3>
            <div className="dcd-details-grid">
              <div className="dcd-detail-item">
                <span className="dcd-detail-label">Part Number</span>
                <span className="dcd-detail-value">{part['Part Number']}</span>
              </div>
              <div className="dcd-detail-item">
                <span className="dcd-detail-label">Part Name</span>
                <span className="dcd-detail-value">{part['Part Name']}</span>
              </div>
              <div className="dcd-detail-item">
                <span className="dcd-detail-label">Category</span>
                <span className="dcd-detail-value">{part.Category}</span>
              </div>
              <div className="dcd-detail-item">
                <span className="dcd-detail-label">PART</span>
                <span className="dcd-detail-value">{part.PART}</span>
              </div>
              {part.url && (
                <div className="dcd-detail-item dcd-product-link-container">
                  <a
                    href={part.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dcd-product-link"
                  >
                    View Product
                    <span className="dcd-link-arrow">→</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DieselCenterDetails; 