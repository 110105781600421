import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './CardDetails.css';
import { DataService } from '../services/DataService';
import Loading from './Loading';
import ProductNotFound from './ProductNotFound';

const CardDetails = () => {
  const { id, type } = useParams();
  const [tool, setTool] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const powertoolsData = DataService.getAutoSparePartsData()
    const foundTool = powertoolsData
      .find(category => category.id === type)?.items
      .find(item => item.BOSCH_PART_NO === id);

    // console.log('foundTool', foundTool);
    window.scrollTo(0, 0);

    setTimeout(() => {  // Optional: Remove this if you don't want artificial delay
      setIsLoading(false);
      if (foundTool) {
        setTool(foundTool);
      }
    }, 500);
  }, [id, type]);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  if (!tool) {
    return (
      <ProductNotFound />
    );
  }

  const bannerImage = () => {
    // console.log('tool.PART', tool.PART);
    if (tool.PART === 'Air Dryer') {
      return '/images/details/commercial-air-dryer.jpeg'
    } else if (tool.PART === 'Air Filter') {
      return '/images/details/commercial-air-filter.jpeg'
    } else if (tool.PART === 'Fuel Filter') {
      return '/images/details/commercial-fuel-filter.jpeg'
    } else if (tool.PART === 'Oil Filter') {
      return '/images/details/commercial-oil-filter.jpeg'
    } else if (tool.PART === 'Ac Cabin Filter') {
      return '/images/details/ac-filter.webp'
    } else if (tool.PART === 'Brake Pad') {
      return '/images/details/brake-pads.jpeg'
    } else if (tool.PART === 'Pump') {
      return '/images/details/fuel-pump.webp'
    } else if (tool.PART === 'Hydraulic Filter') {
      return '/images/details/hydraulic-filter.webp'
    } else if (tool.PART === 'Injector') {
      return '/images/details/injector.webp'
    } else if (tool.PART === 'Sensor') {
      return '/images/details/sensors-belts-horns.webp'
    } else if (tool.PART === 'Water Separator') {
      return '/images/details/water-separator.webp'
    } else if (tool.PART === 'Oil Separator') {
      return '/images/details/oil-separator.webp'
    } else if (tool.PART === 'Air Separator') {
      return '/images/details/air-separator.webp'
    } else if (tool.PART === 'Starter') {
      return '/images/details/starter-alternator.jpeg'
    } else if (tool.PART === 'Alternator') {
      return '/images/details/starter-alternator.jpeg'
    } else if (tool.PART === 'Heavy Duty Tarpaulin') {
      return '/images/details/tarpaulin-banner.jpg'
    } else if (tool.PART === 'Brake Pad & Brake Lining') {
      return '/images/details/brake-banner.jpeg'
    } else {
      return '/images/details/commercial-vehicle-banner.webp'
    }
  }

  return (
    <div className="card-d-details">
      <div className="card-d-banner">
        <img
          src={bannerImage()}
          alt="Power Tools Category Banner"
          className="card-d-banner-image"
        />
        <div className="card-d-banner-overlay">
          <h2 className="card-d-details-title">{tool.PART}</h2>
        </div>
      </div>

      <div className="card-d-details-container">
        <div className="card-d-details-card">
          <div className="card-d-details-section">
            <h3 className="card-d-section-title">Product Details</h3>
            <div className="card-d-details-grid">
              <div className="card-d-detail-item">
                <span className="card-d-detail-label">Part Number</span>
                <span className="card-d-detail-value">{tool.BOSCH_PART_NO || 'NA'}</span>
              </div>
              <div className="card-d-detail-item card-d-description">
                <span className="card-d-detail-label">OE Part Number</span>
                <span className="card-d-detail-value">{tool.OE_PART_NUMBER || 'NA'}</span>
              </div>
              <div className="card-d-detail-item card-d-description">
                <span className="card-d-detail-label">Brand</span>
                <span className="card-d-detail-value">{tool.MAKE || 'NA'}</span>
              </div>
              <div className="card-d-detail-item card-d-description">
                <span className="card-d-detail-label">External Part Number</span>
                <span className="card-d-detail-value">{tool.EXTERNAL_PART_NUMBER || 'NA'}</span>
              </div>
              <div className="card-d-detail-item card-d-description">
                <span className="card-d-detail-label">External Make</span>
                <span className="card-d-detail-value">{tool.EXTERNAL_MAKE || 'NA'}</span>
              </div>
              <div className="card-d-detail-item card-d-description">
                <span className="card-d-detail-label">Vehicle Application</span>
                <span className="card-d-detail-value">{tool.VEHICLE_APPLICATION || 'NA'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="card-d-details">
    //   <h2 className="card-d-details-title">{tool.PART}</h2>

    //   <div className="card-d-details-container">

    //   <div className="card-d-details-group">
    //       <h3>Bosch Part Number</h3>
    //       <p>{tool.BOSCH_PART_NO || 'NA'}</p>
    //     </div>

    //     <div className="card-d-details-group">
    //       <h3>OE Part Number</h3>
    //       <p>{tool.OE_PART_NUMBER || 'NA'}</p>
    //     </div>

    //     <div className="card-d-details-group">
    //       <h3>Make</h3>
    //       <p>{tool.MAKE || 'NA'}</p>
    //     </div>

    //     <div className="card-d-details-group">
    //       <h3>External Part Number</h3>
    //       <p>{tool.EXTERNAL_PART_NUMBER || 'NA'}</p>
    //     </div>

    //     <div className="card-d-details-group">
    //       <h3>External Make</h3>
    //       <p>{tool.EXTERNAL_MAKE || 'NA'}</p>
    //     </div>

    //     <div className="card-d-details-group">
    //       <h3>Vehicle Application</h3>
    //       <p>{tool.VEHICLE_APPLICATION || 'NA'}</p>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CardDetails;