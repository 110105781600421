import { useState, useEffect, useMemo } from 'react';
import './Products.css';
import { useParams, useNavigate } from 'react-router-dom';
import { DataService } from '../services/DataService';
import Card from '../components/Card';
import AccessoriesCard from '../components/AccessoriesCard';
import PowertoolsCard from '../components/PowertoolsCard';
import MachinesCard from '../components/MachinesCard';
import DieselCenterCard from '../components/DieselCenterCard';

const Products = () => {
  const navigate = useNavigate(); // Add this line
  const { category, subcategory } = useParams();
  // console.warn('category', category, 'subcategory', subcategory);

  const [searchTerm, setSearchTerm] = useState('');
  const [title, setTitle] = useState('');
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [activeCategory, setActiveCategory] = useState(subcategory);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Use useMemo to avoid recalculating categories on every render
  const categories = useMemo(() => {
    if (category === 'p-and-m') {
      setTitle('Powertools & Machines');
      return DataService.getPowertoolsAccessoriesData();
    }
    if (category === 'spare-parts') {
      setTitle('Auto Spare Parts');
      return DataService.getAutoSparePartsData();
    }
    if (category === 'diesel-center') {
      setTitle('Diesel Center Spares');
      return DataService.getDieselCenterData();
    }
    return [];
  }, [category]);

  // Set active category when categories change
  useEffect(() => {
    setActiveCategory(subcategory || categories[0]?.id);
    setSearchPlaceholder(`Search ${subcategory || categories[0]?.id}...`);
    setCurrentPage(1);
  }, [subcategory, categories, searchTerm]);

  // const allItems = useMemo(() => categories.flatMap(cat => cat.items), [categories]);

  const searchFieldsMap = useMemo(() => ({
    all: ['Description', 'Part_Nr', 'description', 'id', 'uuid', 'model',
      'BOSCH_PART_NO', 'OE_PART_NUMBER', 'VEHICLE_APPLICATION',
      'EXTERNAL_PART_NUMBER', 'EXTERNAL_MAKE', 'PART', 'Part Number', 'Part Name', 'Category', 'MAKE'],
    accessories: ['Description', 'Part_Nr'],
    machines: ['description', 'id', 'uuid'],
    powertools: ['description', 'model'],
    commercial: ['BOSCH_PART_NO', 'OE_PART_NUMBER', 'VEHICLE_APPLICATION', 'EXTERNAL_PART_NUMBER', 'EXTERNAL_MAKE', 'PART', 'MAKE'],
    passenger: ['BOSCH_PART_NO', 'OE_PART_NUMBER', 'VEHICLE_APPLICATION', 'EXTERNAL_PART_NUMBER', 'EXTERNAL_MAKE', 'PART', 'MAKE'],
    plunger: ['Part Number', 'Part Name', 'Category'],
    spare: ['Part Number', 'Part Name', 'Category'],
    nozzle: ['Part Number', 'Part Name', 'Category'],
  }), []);

  const filteredItems = useMemo(() => {
    const activeItems = categories.find(cat => cat.id === activeCategory)?.items || [];
    return activeItems.filter(item => {
      if (!searchTerm) return true;
      const fieldsToSearch = searchFieldsMap[activeCategory] || [];
      return fieldsToSearch.some(field => {
        const value = item[field];
        return value?.toString().toLowerCase().includes(searchTerm.toLowerCase());
      });
    });
  }, [categories, activeCategory, searchTerm, searchFieldsMap]);

  // const filteredItems = useMemo(() => {
  //   const activeItems = categories.find(cat => cat.id === activeCategory)?.items || [];
  //   return activeItems.filter(item => {
  //     const fieldsToSearch = searchFieldsMap[activeCategory] || [];
  //     return fieldsToSearch.some(field => item[field]?.toLowerCase().includes(searchTerm.toLowerCase()));
  //   });
  // }, [categories, activeCategory, searchTerm, searchFieldsMap]);

  const currentParts = useMemo(() => {
    const indexOfLastPart = currentPage * itemsPerPage;
    const indexOfFirstPart = indexOfLastPart - itemsPerPage;
    return filteredItems.slice(indexOfFirstPart, indexOfLastPart);
  }, [filteredItems, currentPage, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const getPaginationRange = (totalPages, currentPage, maxVisiblePages = 5) => {
    const half = Math.floor(maxVisiblePages / 2);
    let start = Math.max(1, currentPage - half);
    let end = Math.min(totalPages, currentPage + half);

    if (currentPage <= half) {
      end = Math.min(totalPages, maxVisiblePages);
    } else if (currentPage + half >= totalPages) {
      start = Math.max(1, totalPages - maxVisiblePages + 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const handleCategoryChange = (categoryId) => {
    // setActiveCategory(categoryId);
    setCurrentPage(1); // Reset to the first page
    navigate(`/products/${category}/${categoryId}`);
  };

  return (
    <div className='power-tools-accessories-wrapper'>
      <div className="power-tools-accessories-container">
        <div>
          <h1>{title}</h1>
        </div>
      </div>

      <div className="power-tools-accessories-container">
        <div className="p-and-m-search">
          <span className="p-and-m-search-icon"><img src='/images/icons/search.png' alt='search' style={{ width: '22px', height: '22px' }}></img></span>
          <input
            type="text"
            placeholder={searchPlaceholder}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="power-tools-accessories-container">
        <div className="p-and-m-categories">
          <div className="p-and-m-category-buttons">
            {categories.map(category => (
              <button
                key={category.id}
                className={activeCategory === category.id ? 'active' : ''}
                onClick={() => handleCategoryChange(category.id)}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="p-and-m-items">
        {currentParts.map((item, index) => {
          let itemCategory;
          if (activeCategory === 'all') {
            itemCategory = categories.slice(1).find(cat => cat.items.includes(item));
          } else {
            itemCategory = categories.find(cat => cat.id === activeCategory);
          }
          switch (itemCategory?.id) {
            case 'accessories':
              return <AccessoriesCard key={index} item={item} />;
            case 'machines':
              return <MachinesCard key={index} item={item} />;
            case 'powertools':
              return <PowertoolsCard key={index} item={item} />;
            case 'commercial':
              return <Card key={index} part={item} type='commercial' />;
            case 'passenger':
              return <Card key={index} part={item} type='passenger' />;
            case 'plunger':
            case 'spare':
            case 'nozzle':
              return <DieselCenterCard key={index} item={item} />;
            default:
              return null;
          }
        })}
      </div>

      <div className="power-tools-accessories-container">
        <div className="pagination">
          {filteredItems.length > 0 ? (
            <>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {getPaginationRange(Math.ceil(filteredItems.length / itemsPerPage), currentPage).map(pageNumber => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  className={currentPage === pageNumber ? 'active' : ''}
                >
                  {pageNumber}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(filteredItems.length / itemsPerPage)}
              >
                Next
              </button>
            </>
          ) : (
            <p>No items found.</p>
          )}
        </div>
      </div>

    </div>
  );
}

export default Products;